import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../layout/Layout'
import Seo from '../components/Seo'
import { Allieds, Areas, WhyusSection } from '../sections/index'

import hero from '../images/banners/allency-banner-know-us.png'
import SmallHeroBand from '../sections/SmallHeroBand'

const IndexPage = () => {
  const dataImage = useStaticQuery(graphql`
    query {
      allies: file(relativePath: { eq: "stock/allency-know-us.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }

      whyOne: file(relativePath: { eq: "stock/allency-why-choose-us-estrategy.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      whyTwo: file(relativePath: { eq: "stock/allency-why-choose-us-results.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      whyFour: file(relativePath: { eq: "stock/allency-why-choose-us-quality-and-image.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      whyThree: file(relativePath: { eq: "stock/allency-why-choose-us-work-team.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `)

  const allies = dataImage.allies.childImageSharp.gatsbyImageData
  const whyOne = dataImage.whyOne.childImageSharp.gatsbyImageData
  const whyTwo = dataImage.whyTwo.childImageSharp.gatsbyImageData
  const whyThree = dataImage.whyThree.childImageSharp.gatsbyImageData
  const whyFour = dataImage.whyFour.childImageSharp.gatsbyImageData

  const contents = {
    hero: {
      image: hero,
      title: 'Conócenos',
    },
    allies: {
      image: allies,
    },
    whyUs: {
      reasons: [
        {image: whyOne, title: `PLAN ESTRATÉGICO`, description: `
          Creemos fielmente que la mejor manera de conseguir resultados es implementando un plan estratégico y llevarlo a la acción.<br/><br/>
          Nos encargaremos de generarte un plan con las estrategias de publicidad adecuadas para generar resultados integrales en tu proyecto.`, button: 'Saber más', link: '/about-us'},
        {image: whyTwo, title: `RESULTADOS`, description: `
          Estamos comprometidos con tu marca, no hay mejor satisfacción que dejar que los resultados hablen por sí solos.<br/><br/>
          En tu proyecto, le daremos seguimiento a los resultados a través de reportes e indicadores clave de rendimiento.`, button: 'Saber más', link: '/about-us'},
        {image: whyThree, title: `EQUIPO DE TRABAJO`, description: `
          Durante el proyecto contarás con el apoyo de un equipo multidisciplinario que brindará la pasión y resultados que buscas para tu negocio.<br/><br/>
          Trabajarás de la mano con encargados de nuestras áreas de gestión de proyectos, marketing, programación web y diseño gráfico.`, button: 'Saber más', link: '/about-us'},
        {image: whyFour, title: `CALIDAD E IMAGEN`, description: `
          La imagen es importante, por eso buscamos entregar trabajos de primera, revisando hasta los más pequeños detalles.<br/><br/>
          Tendrás el apoyo de expertos en brindarle una imagen profesional a tu marca, transmitir el mensaje de manera correcta y cumplir con todos los objetivos de comunicación.`, button: 'Saber más', link: '/about-us'}
      ]
    }
  }

  return (
    <Layout>
      <Seo
        lang={'es'}
        title={'Somos tus aliados en publicidad'}
        description={"Generamos soluciones integrales de publicidad para negocios en crecimiento, eventos, empresas y marcas posicionadas."}
        keywords={['Publicidad,Aliados,Soluciones,Startups,Negocio,Empresa,Marca,Posicionamiento,Estrategia,Marketing,Gestionamiento,Gestión,Programación,Código,Diseño,Imagen,Resultados']}
        noIndex={''}
        noFollow={''}
        slug={'about-us'}
        canonicalUrl={'https://allency.com.mx/about-us'}
        ogTitle={'Somos tus aliados en publicidad'}
        ogType={'website'}
        ogDescription={"Generamos soluciones integrales de publicidad para negocios en crecimiento, eventos, empresas y marcas posicionadas."}
        ogImage={'../../images/logos/allency-dark.png'}
      />
      <SmallHeroBand contents={contents.hero} headerSpace={true} />
      <Allieds contents={contents} />
      <Areas />
      <WhyusSection contents={contents.whyUs} hideLinks={true} />
    </Layout>
  )
}

export default IndexPage